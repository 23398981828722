<template>
    <div>
        <h1>Freight Accrual</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>

    import { store } from '@/store/Report.store.js';
    import Options from "./freight_opt";
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    import DateHelpers from "@/store/DateHelpers";

    export default {
        name: 'freight',
        components: {Results, Fields, GroupBy, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: DateHelpers.getFirstOfTheMonth(), qsField: '[dates][actual_ship_from]' },
                    endDate: { val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]' },
                    cdiLocationId: { val: 0, qsField: '[numbers][t.cdi_location_id]' },
                    shipperId: { val: 0, qsField: '[numbers][t.shipping_co_id]' },
                    shipToState: { val: 0, qsField: '[strings][sa.state]' },
                    shipToTerritory: { val: 0, qsField: '[numbers][st.territory_id]' },
                    outsideSalesId: {val: 0, qsField: '[subquery][outside_sales]'},
                    insideSalesId: {val: 0, qsField: '[subquery][inside_sales]'},
                    nationalSalesTeams: {val: 0, qsField: '[numbers][d.national_sales_team_id]'},
                    shippingPaid: {val: 0, qsField: '[misc][t.shipping_paid]' },
                    truckingType: {val: 0, qsField: '[numbers][t.trucking_options_id]', defaultVal: 0},
                    specialTag: {val: 0, qsField: '[numbers][d.branch_id]', defaultVal: 0},
                    flatBed: {val: null, qsField: '[numbers][t.flatbed]', defaultVal: null}
                },
                groupByFields: {
                    outside_sales: { label: 'Regional Sales Rep', checked: false},
                    inside_sales: { label: 'Sales Support Rep', checked: false },
                    company: { label: 'Company', checked: false }
                },
                dataFields: {
                    tracker_id: { label: 'Order', checked: true },
                    actual_ship: { label: 'Ship Date', checked: true },
                    shipto_city: { label: 'Ship To City', checked: true },
                    shipto_state: { label: 'Ship To State', checked: true },
                    shipto_zip: { label: 'Zip', checked: true },
                    shipping_co: { label: 'Carrier', checked: true },
                    ship_weight: { label: 'Weight (lb)', checked: true },
                    accrued: {label: 'Freight Goal', checked: false},
                    late_detention_fee: {label: 'Late / Detention Fee', checked: false},
                    fee_type_id: {label: 'Additional Freight Cost Reason', checked: false},
                    freight_cost: { label: 'Freight Invoice', checked: true },
                    surcharge: {label: 'Freight Surcharge', checked: true},
                    total_amount: { label: 'Invoice', checked: true },
                    shipping_type: { label: 'Shipping Type', checked: true},
                    tracking_pro_num: {label: 'PRO #', checked: false},
                    outside_sales: { label: 'Regional Sales Rep', checked: false },
                    inside_sales: { label: 'Sales Support Rep', checked: false },
                    national_sales_team: {label: 'National Sales Team'},
                    company: { label: 'Company', checked: false },
                    qty: { label: 'Item Count', checked: false },
                    shipping_paid: { label: 'Shipping Paid', checked: true }
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Freight Accrual']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>