<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label>
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id" >{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shipper</label>
                <Typeahead cdi_type='shipping_co' v-bind:ID.sync="state.optionFields.shipperId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship to State</label>
                <select v-model="state.optionFields.shipToState.val" class="form-control">
                    <option value="0">None</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship to Territory</label>
                <select class="form-control" v-model="state.optionFields.shipToTerritory.val">
                    <option value="0">All</option>
                    <option v-for="[id, territory] in cache.salesDataCache.SALES_TERRITORIES" v-bind:key="id" :value="id">{{ territory }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Regional Sales Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.outsideSalesId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Support Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.insideSalesId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
              <label>National Sales Team</label>
              <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                <option value="0"></option>
                <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
              </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shipping Paid</label>
                <select class="form-control" v-model="state.optionFields.shippingPaid.val">
                    <option value="0">All</option>
                    <option v-for="[id, option] in shippingPaid" v-bind:key="id" :value="id">{{ option }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group"  v-show="!toggleHide">
                <label>Trucking Type</label>
                <select class="form-control" v-model="state.optionFields.truckingType.val">
                    <option value="0">None</option>
                    <option v-for="[id, type] in cache.shippingCache.SHIPPING_OPTIONS" v-bind:key="id" :value="id">{{ type }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Special Tag</label>
                <select class="form-control" v-model="state.optionFields.specialTag.val">
                    <option value="0">All</option>
                    <option v-for="[id, tag] in cache.userCache.USER_SPECIAL_TAGS" v-bind:key="id" :value="id">{{ tag }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid toggle_target'  v-show="!toggleHide">
                <label><input v-model="state.optionFields.flatBed.val" :true-value="1" :false-value=null  type='checkbox' > Flatbed</label>
            </div>

            <div class="col-xs-12"><button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>

    import { store } from "@/store/Report.store";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";
    import stateCache from "@/cache/state.cache.js";
    import salesDataCache from "@/cache/salesData.cache.js";
    import shippingCache from "@/cache/shipping.cache.js";
    import userCache from "@/cache/user.cache.js";
    import DatePicker from "../utils/DatePicker";
    import Typeahead from '@/components/utils/typeaheads/Typeahead';

    export default {
        name: 'Options',
        components: {DatePicker, Typeahead},
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    stateCache,
                    salesDataCache,
                    shippingCache,
                    userCache
                },
              nationalSalesTeams: [
                [0,'Options Not Available']],
                shippingPaid: [
                    [2, 'No'],
                    [1, 'Yes']
                ]
            }
        },
        created() {
          this.get_national_sales_teams();
        },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
            },
            get_national_sales_teams: function() {
              store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }

    }

</script>