var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel panel-default toggle_container"},[_c('div',{staticClass:"panel-heading"}),_c('div',{staticClass:"row panel-body"},[_c('div',{staticClass:"date_picker col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Start Date")]),_c('DatePicker',{attrs:{"input-attributes":{ class: 'form-control' }},model:{value:(_vm.state.optionFields.startDate.val),callback:function ($$v) {_vm.$set(_vm.state.optionFields.startDate, "val", $$v)},expression:"state.optionFields.startDate.val"}})],1),_c('div',{staticClass:"date_picker col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("End Date")]),_c('DatePicker',{attrs:{"input-attributes":{ class: 'form-control' }},model:{value:(_vm.state.optionFields.endDate.val),callback:function ($$v) {_vm.$set(_vm.state.optionFields.endDate, "val", $$v)},expression:"state.optionFields.endDate.val"}})],1),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("CDI Location")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.cdiLocationId.val),expression:"state.optionFields.cdiLocationId.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.cdiLocationId, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("All")]),_vm._l((_vm.cache.cdiLocationCache.CDI_LOCATIONS),function(ref){
var id = ref[0];
var location = ref[1];
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(location))])})],2)]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Shipper")]),_c('Typeahead',{attrs:{"cdi_type":"shipping_co","ID":_vm.state.optionFields.shipperId.val},on:{"update:ID":function($event){return _vm.$set(_vm.state.optionFields.shipperId, "val", $event)},"update:i-d":function($event){return _vm.$set(_vm.state.optionFields.shipperId, "val", $event)}}})],1),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Ship to State")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.shipToState.val),expression:"state.optionFields.shipToState.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.shipToState, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("None")]),_vm._l((_vm.cache.stateCache.STATES),function(ref){
var stateAbbr = ref[0];
var state = ref[1];
return _c('option',{key:stateAbbr,domProps:{"value":stateAbbr}},[_vm._v(_vm._s(state))])})],2)]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Ship to Territory")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.shipToTerritory.val),expression:"state.optionFields.shipToTerritory.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.shipToTerritory, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("All")]),_vm._l((_vm.cache.salesDataCache.SALES_TERRITORIES),function(ref){
var id = ref[0];
var territory = ref[1];
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(territory))])})],2)]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Regional Sales Rep")]),_c('Typeahead',{attrs:{"cdi_type":"sales_employee","ID":_vm.state.optionFields.outsideSalesId.val},on:{"update:ID":function($event){return _vm.$set(_vm.state.optionFields.outsideSalesId, "val", $event)},"update:i-d":function($event){return _vm.$set(_vm.state.optionFields.outsideSalesId, "val", $event)}}})],1),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Sales Support Rep")]),_c('Typeahead',{attrs:{"cdi_type":"sales_employee","ID":_vm.state.optionFields.insideSalesId.val},on:{"update:ID":function($event){return _vm.$set(_vm.state.optionFields.insideSalesId, "val", $event)},"update:i-d":function($event){return _vm.$set(_vm.state.optionFields.insideSalesId, "val", $event)}}})],1),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("National Sales Team")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.nationalSalesTeams.val),expression:"state.optionFields.nationalSalesTeams.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.nationalSalesTeams, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}}),_vm._l((_vm.nationalSalesTeams),function(ref){
var id = ref[0];
var location = ref[1];
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(location))])})],2)]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Shipping Paid")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.shippingPaid.val),expression:"state.optionFields.shippingPaid.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.shippingPaid, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("All")]),_vm._l((_vm.shippingPaid),function(ref){
var id = ref[0];
var option = ref[1];
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(option))])})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.toggleHide),expression:"!toggleHide"}],staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Trucking Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.truckingType.val),expression:"state.optionFields.truckingType.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.truckingType, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("None")]),_vm._l((_vm.cache.shippingCache.SHIPPING_OPTIONS),function(ref){
var id = ref[0];
var type = ref[1];
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(type))])})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.toggleHide),expression:"!toggleHide"}],staticClass:"col-md-3 col-sm-4 col-xs-6 form-group"},[_c('label',[_vm._v("Special Tag")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.specialTag.val),expression:"state.optionFields.specialTag.val"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.state.optionFields.specialTag, "val", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"0"}},[_vm._v("All")]),_vm._l((_vm.cache.userCache.USER_SPECIAL_TAGS),function(ref){
var id = ref[0];
var tag = ref[1];
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(_vm._s(tag))])})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.toggleHide),expression:"!toggleHide"}],staticClass:"col-md-3 col-sm-4 col-xs-6 form_grid toggle_target"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.optionFields.flatBed.val),expression:"state.optionFields.flatBed.val"}],attrs:{"true-value":1,"false-value":null,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.state.optionFields.flatBed.val)?_vm._i(_vm.state.optionFields.flatBed.val,null)>-1:_vm._q(_vm.state.optionFields.flatBed.val,1)},on:{"change":function($event){var $$a=_vm.state.optionFields.flatBed.val,$$el=$event.target,$$c=$$el.checked?(1):(null);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.state.optionFields.flatBed, "val", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.state.optionFields.flatBed, "val", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.state.optionFields.flatBed, "val", $$c)}}}}),_vm._v(" Flatbed")])]),_c('div',{staticClass:"col-xs-12"},[_c('button',{staticClass:"btn btn-default btn-sm",attrs:{"type":"button"},on:{"click":_vm.toggleMoreFilters}},[_vm._v(_vm._s((_vm.toggleHide) ? 'More' : 'Less')+" Filters")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }